import { ref } from '@vue/composition-api'
import store from '@/store'

export const useMediaFiltersSortingAndPagination = () => {
  const filters = ref({
    q: '',
    UploadList: 'Last Hour',
    categories: [],
    media: [],
    stream: null,
    page: 1,
    perPage: 9,
  })

  const filterOptions = {
    upload: {
      name: 'Upload Date',
      UploadList: [
        { text: 'Last Hour', value: 'Last Hour' },
        { text: 'This Week', value: 'This Week' },
        { text: 'This Month', value: 'This Month' },
        { text: 'This Year', value: 'This Year' },
      ],
    },
    priority: {
      name: 'Priority',
      categories: ['Low', 'Medium', 'High'],
    },
    media: {
      name: 'Media',
      list: ['Videos', 'Photos'],
    },
    state: {
      name: 'State',
      list: [
        {
          state: 'Johor',
          total: 328,
        },
        {
          state: 'Kedah',
          total: 608,
        },
        {
          state: 'Kelantan',
          total: 482,
        },
        {
          state: 'Melaka',
          total: 480,
        },
        {
          state: 'Negeri Sembilan',
          total: 205,
        },
        {
          state: 'Pahang',
          total: 818,
        },
        {
          state: 'Penang',
          total: 291,
        },
        {
          state: 'Perak',
          total: 620,
        },
        {
          state: 'Perlis',
          total: 366,
        },
        {
          state: 'Sabah',
          total: 366,
        },
        {
          state: 'Sarawak',
          total: 366,
        },
        {
          state: 'Selangor',
          total: 366,
        },
        {
          state: 'Terengganu',
          total: 366,
        },
      ],
    },
    stream: {
      name: 'Input Stream',
      list: [
        {
          input: 'CCTV',
          total: 328,
        },
        {
          input: 'Smart Helmet',
          total: 608,
        },
        {
          input: 'Smart Glasses',
          total: 480,
        },
        {
          input: 'IOT Devices',
          total: 205,
        },
        {
          input: 'Mobile Devices',
          total: 818,
        },
        {
          input: 'Orthophotograh',
          total: 291,
        },
        {
          input: 'Point Cloud',
          total: 620,
        },
        {
          input: 'Mesh',
          total: 366,
        },
      ],
    },
  }

  // Sorting
  const sortBy = ref({ text: 'Recent', value: 'featured' })
  const sortByOptions = [
    { text: 'Recent', value: 'featured' },
    { text: 'Latest', value: 'latest' },
    { text: 'Popular', value: 'popular' },
  ]

  return {
    // Filter
    filters,
    filterOptions,

    // Sort
    sortBy,
    sortByOptions,
  }
}

export const useMediaUi = () => {
  const itemView = 'grid-view'
  const itemViewOptions = [
    { icon: 'GridIcon', value: 'grid-view' },
    { icon: 'ListIcon', value: 'list-view' },
  ]

  // Pagination count <= required by pagination component
  const totalProducts = ref(null)

  return {
    itemView,
    itemViewOptions,
    totalProducts,
  }
}

export const useVideoRemoteData = () => {
  const videos = ref([])
  const fetchVideos = (...args) => store.dispatch('media-videos/fetchVideos', ...args)

  return {
    videos,
    fetchVideos,
  }
}
