<template>
  <div>
    <div>
      <div style="height: inherit">
        <section id="ecommerce-header">
          <div class="row">
            <div class="col-sm-12">
              <div class="ecommerce-header-items">
                <div class="result-toggler">
                  <div class="search-results">
                    {{ fileLists.length }} media found
                  </div>
                </div>
                <div class="view-options d-flex">

                  <b-button
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    v-b-modal.modal-center
                    class="mr-1"
                    variant="primary"
                    block
                  >
                    Upload Images
                  </b-button>

                  <!-- Sort Button -->
                  <!-- <b-dropdown
                    v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                    :text="sortBy.text"
                    right
                    variant="outline-primary"
                  >
                    <b-dropdown-item
                      v-for="sortOption in sortByOptions"
                      :key="sortOption.value"
                      @click="sortBy=sortOption"
                    >
                      {{ sortOption.text }}
                    </b-dropdown-item>
                  </b-dropdown> -->
                  <b-form-radio-group
                    v-model="itemView"
                    class="ml-1 list item-view-radio-group"
                    buttons
                    size="sm"
                    button-variant="outline-primary"
                  >
                    <b-form-radio
                      v-for="option in itemViewOptions"
                      :key="option.value"
                      :value="option.value"
                    >
                      <div>
                        <!-- @click="viewType(option.value)" -->
                        <feather-icon
                          :icon="option.icon"
                          size="18"
                          @click="viewType(option.value)"
                        />
                      </div>
                    </b-form-radio>
                  </b-form-radio-group>
                </div>
              </div>
            </div>
          </div>
        </section>
        <div class="body-content-overlay" />
        <div class="ecommerce-searchbar mt-1">
          <b-row>
            <b-col cols="12">
              <b-input-group class="input-group-merge">
                <b-form-input
                  v-model="filters.q"
                  placeholder="Search here"
                  class="search-product"
                />
                <b-input-group-append is-text>
                  <feather-icon
                    icon="SearchIcon"
                    class="text-muted"
                  />
                </b-input-group-append>
              </b-input-group>
            </b-col>
          </b-row>
        </div>
        <section :class="itemView">
          <div
            v-for="product in fileLists"
            :key="product.id"
          >
            <div v-if="gridView">
              <b-card
                class="ecommerce-card"
                no-body
              >
                <div
                  v-if="product.s3imageUrl.split('.').at(-1).toLowerCase() != 'mp4'"
                  class="item-img text-center"
                  style="height: 200px; padding: 0px;"
                  @click="openView(product.s3imageUrl)"
                >

                  <b-img
                    :alt="`${product.id}`"
                    class="card-img-top"
                    style="height: 100%; background-size: cover;"
                    :src="product.s3imageUrl"
                  />

                </div>
                <div
                  v-else
                  style="background-color: lightgray; padding: 56px;"
                  class="d-flex justify-content-center"
                  @click="openView(product.s3imageUrl)"
                >
                  <video
                    poster="../video_icon.png"
                    style="height: 110px;"
                    class=""
                  >
                    <source
                      :src="`${product.s3imageUrl}`"
                      type="video/mp4"
                      autoplay
                    >
                  </video>

                  <b-card-body>
                    <div class="item-wrapper">
                      <div class="item-rating">
                        <span style="color: #00CFE8;">{{ product.pb }}</span>
                      </div>
                      <div>
                        <h6 class="item-price">
                          {{ convertedDate(product.date) }}
                        </h6>
                      </div>
                    </div>
                    <h6 class="item-name">

                      {{ product.originalFileName }}
                    </h6>
                    <b-card-text class="item-description">
                      {{ product.address }}
                    </b-card-text>
                  </b-card-body>
                </div>
                <!-- </label></div> -->
                <div class="item-options text-center">
                  <b-button
                    variant="light"
                    tag="a"
                    class="btn-wishlist"
                    @click="openView(product.s3imageUrl)"
                  >
                    <feather-icon
                      icon="FileTextIcon"
                      class="mr-50"
                    />
                    <span>Details</span>
                  </b-button>
                  <b-button
                    variant="primary"
                    target="_blank"
                    download=""
                    class="btn-cart"
                    @click="downloadFile(product.s3imageUrl)"
                  >
                    <feather-icon
                      icon="DownloadIcon"
                      class="mr-50"
                    />
                    <span>Download</span>
                  </b-button>
                </div>

              </b-card>
            </div>

            <div
              v-if="listView"
            >
              <div class="card">
                <!-- <div
                  class=""
                > -->
                <div class="row">
                  <div class="col-4 d-flex justify-content-start">
                    <div class="row">
                      <div
                        class="pl-1"
                        style="width: 120px;"
                      >
                        <div
                          v-if="product.s3imageUrl.split('.').at(-1).toLowerCase() != 'mp4'"
                          class=""
                          style="padding: 0px; "
                          @click="openView(product.s3imageUrl)"
                        >

                          <b-img
                            :alt="`${product.id}`"
                            class="card-img-top rounded"
                            style="background-size: cover;"
                            :src="product.s3imageUrl"
                          />

                        </div>
                        <div
                          v-else
                          style="background-color: lightgray; padding: 56px;"
                          class="d-flex justify-content-center"
                          @click="openView(product.s3imageUrl)"
                        >
                          <video
                            poster="../video_icon.png"
                            style="padding: 0px;"
                            class=""
                          >
                            <source
                              :src="`${product.s3imageUrl}`"
                              type="video/mp4"
                              autoplay
                            >
                          </video>
                        </div>
                      </div>
                      <!-- </div> -->
                      <div class="p-1">
                        <div style="">
                          <div>
                            <h6 class="item-name">

                              {{ product.originalFileName }}
                            </h6>
                            <span style="color: #6E6B7B;">Composition 24-71, Carriage 6, Camera 2, …</span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <!-- <div>aaaaaaaaaaaaaaa</div> -->
                  <div class="col-4 d-flex justify-content-center">
                    <div class="row">
                      <!-- <div class="d-flex justify-content-space-between"> -->
                      <div class="p-2">
                        <h6 class="item-price">
                          Johor
                        </h6>
                      </div>
                      <div class="p-2">
                        <span style="color: #00cfe8;">
                          1563-83 PB
                        </span>
                      </div>
                    </div>
                  </div>
                  <div class="col-4 d-flex justify-content-end">
                    <!-- </div> -->
                    <div class="p-1">
                      <b-dropdown
                        variant="link"
                        toggle-class="text-decoration-none"
                        no-caret
                      >
                        <template v-slot:button-content>
                          <feather-icon
                            icon="MoreVerticalIcon"
                            size="16"
                            class="text-body align-middle mr-25 purpleColor"
                          />
                        </template>
                        <b-dropdown-item>
                          <feather-icon
                            icon="Edit2Icon"
                            class="mr-50"
                          />
                          <span>Edit</span>
                        </b-dropdown-item>
                        <b-dropdown-item>
                          <feather-icon
                            icon="TrashIcon"
                            class="mr-50"
                          />
                          <span> Delete</span>
                        </b-dropdown-item>
                      </b-dropdown>
                    </div>
                  <!-- </div> -->
                  </div>
                  <!-- <div class="bg-warning" /> -->
                </div>
                <!-- </div> -->
              </div>
            </div>
          </div>

        </section>
        <section>
          <b-row>
            <b-col cols="12">
              <b-pagination
                v-model="filters.page"
                :total-rows="totalProducts"
                :per-page="filters.perPage"
                first-number
                align="center"
                last-number
                prev-class="prev-item"
                next-class="next-item"
              >
                <template #prev-text>
                  <feather-icon
                    icon="ChevronLeftIcon"
                    size="18"
                  />
                </template>
                <template #next-text>
                  <feather-icon
                    icon="ChevronRightIcon"
                    size="18"
                  />
                </template>
              </b-pagination>
            </b-col>
          </b-row>
        </section>
        <b-modal
          id="modal-video"
          hide-footer
          centered
          hide-header
          size="lg"
          body-class="noBackground"
        >
          <div
            class="d-flex justify-content-center"
            style="position: relative"
          >

            <vue-plyr
              :options="options"
            >
              <video
                autoplay
                controls
                crossorigin
                playsinline
              >
                <source
                  size="1080"
                  :src="`${srcVid}`"
                  type="video/mp4"
                >
                <track
                  default
                  kind="captions"
                  label="English captions"
                  src="/path/to/english.vtt"
                  srclang="en"
                >
              </video>
            </vue-plyr>
            <div
              class="bg-white divCloseBtn"
              style="position: absolute; right: -30px; top: -18px;"
              @click="imgCloseVid"
            >
              <feather-icon
                icon="XIcon"
                size="44"
                class="align-middle text-body closeBtn"
              />
            </div>
          </div>
        </b-modal>
        <b-modal
          id="modal-image"
          hide-footer
          centered
          hide-header
          body-class="noBackground"
        >
          <div
            class="d-flex justify-content-center"
            style="position: relative"
          >

            <img
              style="display: block;
              width: 200%;
              max-height: 70%"
              width="900"
              height="700"
              :src="`${srcImg}`"
            >
            <div
              class="bg-white divCloseBtn"
              style="position: absolute; right: -260px; top: -18px;"
              @click="imgClose"
            >
              <feather-icon
                icon="XIcon"
                size="44"
                class="align-middle text-body closeBtn"
              />
            </div>
          </div>
        </b-modal>
        <b-modal
          id="modal-center"
          hide-footer
          header-bg-variant="white"
          size="lg"
          centered

          @show="resetModal"
        >
          <div class="p-2">
            <b-form>
              <div class="row">
                <div class="d-flex flex-column col-sm-6">
                  <b-form-group
                    label="Folder Name"
                    label-for="File-title"
                  >
                    <b-form-input
                      id="file-title"
                      v-model="fileTitle"
                    />
                  </b-form-group>
                </div>
                <div
                  class="d-flex flex-column col-sm-6"
                >
                  <b-form-group
                    label="Date"
                    label-for="date"
                  >
                    <b-input-group class="mb-1">
                      <b-form-input
                        id="date"
                        v-model="selectedDate"
                        type="text"
                        placeholder="YYYY-MM-DD"
                        autocomplete="off"
                        show-decade-nav
                      />
                      <b-input-group-append>
                        <b-form-datepicker
                          v-model="selectedDate"
                          show-decade-nav
                          button-only
                          button-variant="outline-primary"
                          right
                          size="sm"
                          locale="en-US"
                          aria-controls="date"
                          @context="onContext"
                        />
                      </b-input-group-append>
                    </b-input-group>
                  </b-form-group>
                </div>
              </div>
            </b-form>
            <div v-if="selectedDate !== '' && fileTitle !== null">
              <vue-dropzone
                id="dropzone"
                ref="vue2Dropzone"
                class="dropzoneClass"
                :options="dropzoneOptions"
                @vdropzone-total-upload-progress="progressUpload"
                @vdropzone-files-added="uploadFile"
              ><div
                class="dz-default dz-message"
                data-dz-message
              >
                <span>Your Custom Message</span>
              </div></vue-dropzone>
            </div>
          </div>
        </b-modal>
        <portal to="content-renderer-sidebar-detached-left">
          <media-gallery-left-filter-sidebar
            :filters="filters"
            :filter-options="filterOptions"
            :mq-shall-show-left-sidebar.sync="mqShallShowLeftSidebar"
          />
        </portal>
      </div>
    </div>
  </div>
</template>
<style>
.divCloseBtn{
  transition: transform .2s;
  border-radius: 6px;

}
/* [dir] .modal-body {
  padding: 0px;
} */
.divCloseBtn:hover {
  transform: scale(1.2);
}
.noBackground{
background-color: rgb(154, 154, 154)!important;
width:"900";
            height:"500";
}</style>

<script>
import {
  BDropdown, BDropdownItem, BFormRadioGroup, BFormRadio, BRow, BCol, BInputGroup, BInputGroupAppend, BFormInput, BCard, BCardBody, BImg, BCardText, BButton, BPagination, BModal, BForm, BFormDatepicker, BFormGroup,
} from 'bootstrap-vue'
//  BDropdown, BDropdownItem, BFormRadioGroup, BFormRadio,
import Ripple from 'vue-ripple-directive'
import { watch } from '@vue/composition-api'
import { useResponsiveAppLeftSidebarVisibility } from '@core/comp-functions/ui/app'
import moment from 'moment'
import vue2Dropzone from 'vue2-dropzone'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import axios from 'axios'
import MediaGalleryLeftFilterSidebar from './MediaGalleryLeftFilterSidebar.vue'
import { useMediaFiltersSortingAndPagination, useMediaUi, useVideoRemoteData } from './useMediaGalleryDeep'

import 'vue2-dropzone/dist/vue2Dropzone.min.css'
import 'dropzone-vue/dist/dropzone-vue.common.css'

export default {
  directives: {
    Ripple,
  },
  components: {
    // BSV
    vueDropzone: vue2Dropzone,
    BForm,
    BModal,
    BFormDatepicker,
    BFormGroup,
    BDropdown,
    BDropdownItem,
    BFormRadioGroup,
    BFormRadio,
    BRow,
    BCol,
    BInputGroup,
    BInputGroupAppend,
    BFormInput,
    BCard,
    BCardBody,
    BImg,
    BCardText,
    BButton,
    BPagination,

    // SFC
    MediaGalleryLeftFilterSidebar,
  },
  data() {
    return {
      files: [],
      tempData: {},
      gridView: true,
      listView: false,
      publicPath: process.env.BASE_URL,
      fileLists: [],
      srcVid: '',
      srcImg: '',
      isUploadFile: true,
      isFileGroup: false,
      fileTitle: '',
      selectedDate: '',
      options: { quality: { default: '1080p' } },
      dropzoneOptions: {
        uploadMultiple: true,
        url: 'https://httpbin.org/post',
        acceptedFiles: ".jpg, .jpeg, .png, .mp4, .mov, .mkv",
        // maxFiles: 10,
        // clickable: false,
        dictDefaultMessage: 'Drop your files here<p>or</p><div class="btn btn-primary" style="width: 150px" @click="uploadFile">Browse</div>',
        // thumbnailWidth: 150,
        // maxFilesize: 0.5,
        // autoProcessQueue: false,
      },
    }
  },
  mounted() {
    this.apiGetAllFiles()
  },
  setup() {
    const {
      filters, filterOptions, sortBy, sortByOptions,
    } = useMediaFiltersSortingAndPagination()

    const {
      itemView, itemViewOptions, totalProducts,
    } = useMediaUi()

    const { videos, fetchVideos } = useVideoRemoteData()

    const { mqShallShowLeftSidebar } = useResponsiveAppLeftSidebarVisibility()

    // Wrapper Function for `fetchVideos` which can be triggered initially and upon changes of filters
    const fetchMediaVideos = () => {
      fetchVideos({
        q: filters.value.q,
        sortBy: sortBy.value.value,
        page: filters.value.page,
        perPage: filters.value.perPage,
      })
        .then(response => {
          videos.value = response.data.videos
          totalProducts.value = response.data.total
        })
    }

    fetchMediaVideos()

    watch([filters, sortBy], () => {
      fetchMediaVideos()
    }, {
      deep: true,
    })

    return {
      // useMediaFiltersSortingAndPagination
      filters,
      filterOptions,
      sortBy,
      sortByOptions,

      // useShopUi
      itemView,
      itemViewOptions,
      totalProducts,

      // useVideoRemoteData
      videos,

      // mqShallShowLeftSidebar
      mqShallShowLeftSidebar,
    }
  },
  methods: {
    progressUpload(progressUpload){
      console.log(progressUpload)
      if (progressUpload === 100) {
        console.log('iya atau tidak')
        console.log(this.tempData)
        this.$axios.post(`${this.$baseUrl}/S3upload/create`, this.tempData).then(response => {
          if (response.status === 200 || response.status === 201) {
            this.$toast.success("File Successully Uploaded!")
            // this.isUploadFile = false
            // this.isFileGroup = true
            // this.fileObj = response.data
            console.log(response.data)
            this.createFileGroup(response.data)
          } else {
            this.$toast({
              component: ToastificationContent,
              position: "top-right",
              props: {
                title: `Info`,
                icon: "CoffeeIcon",
                variant: "danger",
                text: `Failed to add file(s)!`,
              },
            })
          }
        })
      }
    },
    downloadFile(val) {
      console.log(val)
      axios({ url: val, method: 'GET', responseType: 'blob' }).then(response => {
        const fileUrl = window.URL.createObjectURL(new Blob([response.data]))
        const fileLink = document.createElement('a')
        fileLink.href = fileUrl

        fileLink.setAttribute('download', 'image.png')
        document.body.appendChild(fileLink)

        fileLink.click()
      })
      // const url = window.URL.createObjectURL(new Blob([val]))
      // const link = document.createElement("a")
      // link.href = url
      // link.setAttribute("download", "image") // or any other extension
      // document.body.appendChild(link)
      // link.click()
    },
    viewType(val){
      console.log(val)
      if (val === 'list-view') {
        this.gridView = false
        this.listView = true
      } else {
        this.listView = false
        this.gridView = true
      }
    },
    submitForm(files, response) {
      console.log(files)
      console.log(response)
      // const formData = new FormData()
      // formData.append('projectId', this.hardcodedProjectID)
      // formData.append('bucket', 'gallery')
      // formData.append('file', this.files)

      // console.log(this.files)
      // console.log(formData)
      // this.$axios.post(`${this.$baseUrl}/S3upload/create`, formData).then(response => {
      //   if (response.status === 200 || response.status === 201) {
      //     // this.$toast.success("File Successully Uploaded!")
      //     // this.isUploadFile = false
      //     // this.isFileGroup = true
      //     // this.fileObj = response.data
      //     this.createFileGroup(response.data)
      //   } else {
      //     this.$toast({
      //       component: ToastificationContent,
      //       position: "top-right",
      //       props: {
      //         title: `Info`,
      //         icon: "CoffeeIcon",
      //         variant: "danger",
      //         text: `Failed to add file(s)!`,
      //       },
      //     })
      //   }
      // })
    },
    successUpload(data) {
      this.tempData = data
      this.tempData.forEach((val, key) => {
        console.log(key, val)
      })
    },
    convertedDate(date){
      return moment(date, '').format('DD-MM-YYYY')
    },
    onContext(ctx) {
      // The following will be an empty string until a valid date is entered
      this.selectedDate = ctx.selectedYMD

      this.date = moment(this.selectedDate, 'DD-MM-YYYY').toDate().valueOf()
      console.log(this.date)
    },
    resetModal(){
      this.fileTitle = null
      this.date = null
    },
    uploadFile(value) {
      console.log(value)
      console.log('test berapa kali masuk')
      const formData = new FormData()
      formData.append('projectId', this.hardcodedProjectID)
      formData.append('bucket', 'gallery')
      const files = [...value]
      files.forEach(file => {
        formData.append('file', file)
      })
      console.log(formData)
      this.successUpload(formData)
    },
    createFileGroup(data) {
      console.log(data)
      const FilesGroup = {
        title: this.fileTitle,
        project: "61b17b9a5b46fc0725091390",
        timestamp: this.date,
        items: data,
      }
      console.log(FilesGroup)
      this.$axios.post(`${this.$baseUrl}/files`, FilesGroup).then(response2 => {
        if (response2.status === 200 || response2.status === 201) {
          this.$emit('refresh')
          this.$toast.success("File Successully Created!")
          this.$bvModal.hide('modal-center')
        } else {
          this.$toast({
            component: ToastificationContent,
            position: "top-right",
            props: {
              title: `Info`,
              icon: "CoffeeIcon",
              variant: "danger",
              text: `Error!`,
            },
          })
        }
      })
    },
    openView(srcs) {
      console.log(srcs)
      const format = srcs.split('.').at(-1).toLowerCase()
      if (format === 'mp4' || format === 'mov' || format === "3gp") {
        this.srcVid = srcs
        this.$bvModal.show('modal-video')
      } else {
        this.srcImg = srcs
        this.$bvModal.show('modal-image')
      }
    },
    imgCloseVid(){
      this.$bvModal.hide('modal-video')
    },
    imgClose(){
      this.$bvModal.hide('modal-image')
    },
    apiGetAllFiles(){
      // var tempObj = {}
      this.$axios.get(`${this.$baseUrl}/files`).then(response => {
        if (response.status === 200 || response.status === 201) {
          response.data.forEach(zone => {
            console.log(zone)
            // if (zone.items.length !== 0 || zone.items.length != null)
            zone.items.forEach(val => {
              // eslint-disable-next-line no-param-reassign
              val.date = moment(val.date).format('YYYY-MM-DD')
              console.log(val.originalFileName)
              console.log(val.originalFileName.split('.').at(-1))
              if (val.originalFileName.split('.').at(-1).toLowerCase() === 'jpg' || val.originalFileName.split('.').at(-1).toLowerCase() === 'jpeg' || val.originalFileName.split('.').at(-1).toLowerCase() === 'png') {
                this.fileLists.push(val)
              } else if (val.originalFileName.split('.').at(-1).toLowerCase() === 'mp4') {
                this.fileLists.push(val)
              }
            })
          })
          console.log(this.fileLists)
        }
      })
    },
    detailVideo(){
      this.$router.push({ name: 'media-gallery-details' })
    },
  },
}
</script>

<style lang="scss">
@import "~@core/scss/base/pages/app-ecommerce.scss";
</style>

<style lang="scss" scoped>
.item-view-radio-group ::v-deep {
  .btn {
    display: flex;
    align-items: center;
  }
}
</style>
