<template>
  <div class="sidebar-detached sidebar-left">
    <div class="sidebar">
      <div
        class="sidebar-shop"
        :class="{'show': mqShallShowLeftSidebar}"
      >
        <b-row>
          <b-col cols="12">
            <h6 class="filter-heading d-none d-lg-block">
              Filters
            </h6>
          </b-col>
        </b-row>

        <!-- Filters' Card -->
        <b-card>

          <!-- Multi Range -->
          <div class="multi-range-price">
            <h6 class="filter-title mt-0">
              {{ filterOptions.upload.name }}
            </h6>
            <b-form-radio-group
              v-model="filters.UploadList"
              class="price-range-defined-radio-group"
              stacked
              :options="filterOptions.upload.UploadList"
            />
          </div>

          <!-- Categories -->
          <div class="product-categories">
            <h6 class="filter-title">
              {{ filterOptions.priority.name }}
            </h6>
            <b-form-radio-group
              v-model="filters.categories"
              class="categories-radio-group"
              stacked
              :options="filterOptions.priority.categories"
            />
          </div>

          <!-- Brands -->
          <div class="brands">
            <h6 class="filter-title">
              {{ filterOptions.media.name }}
            </h6>
            <b-form-radio-group
              v-model="filters.media"
              class="brands-radio-group"
              stacked
              :options="filterOptions.media.list"
            />
          </div>

          <!-- Ratings -->
          <div class="ratings">
            <h6 class="filter-title">
              {{ filterOptions.state.name }}
            </h6>
            <div
              v-for="(item, index) in filterOptions.state.list"
              :key="index"
              class="d-flex justify-content-between"
              style="margin-bottom: 10px"
            >
              <b-form-checkbox
                :value="item.state"
                class=""
                stacked
              >  {{ item.state }} </b-form-checkbox>
              <div class="">
                {{ item.total }}
              </div>
            </div>
          </div>

          <div class="ratings">
            <h6 class="filter-title">
              {{ filterOptions.stream.name }}
            </h6>
            <div
              v-for="(item, index) in filterOptions.stream.list"
              :key="index"
              class="d-flex justify-content-between"
              style="margin-bottom: 10px"
            >
              <b-form-checkbox
                :value="item.input"
                class=""
                stacked
              >  {{ item.input }} </b-form-checkbox>
              <div class="">
                {{ item.total }}
              </div>
            </div>
          </div>
        </b-card>
      </div>
    </div>

    <div
      class="body-content-overlay"
      :class="{'show': mqShallShowLeftSidebar}"
      @click="$emit('update:mq-shall-show-left-sidebar', false)"
    />
  </div>
</template>

<script>
import {
  BRow, BCol, BCard, BFormRadioGroup, BFormCheckbox,
} from 'bootstrap-vue'

export default {
  components: {
    BRow,
    BCol,
    BFormRadioGroup,
    BCard,
    BFormCheckbox,

    // 3rd Party
  },
  props: {
    filters: {
      type: Object,
      required: true,
    },
    filterOptions: {
      type: Object,
      required: true,
    },
    mqShallShowLeftSidebar: {
      type: Boolean,
      required: true,
    },
  },
  data(){
    return {
      filterList: {
        upload: {},
        priority: {},
        media: {},
        stream: {},
        state: {},
      },
    }
  },
  // created() {
  //   this.$http.get('/mediaGallery/data')
  //     .then(response => {
  //       console.log(response)
  //       const dataList = response.data.filterList
  //       this.filterList = dataList
  //     })
  // },
}
</script>

<style lang="scss">
@import '~@core/scss/vue/libs/vue-slider.scss';
</style>

<style lang="scss" scoped>
.categories-radio-group,
.brands-radio-group,
.price-range-defined-radio-group {
    ::v-deep > .custom-control {
    margin-bottom: 0.75rem;
  }
}
</style>
